

import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import './FullAuto.css';
import { ClearRounded, KeyboardArrowDownRounded, SearchRounded, Settings } from '@mui/icons-material';
import { Autocomplete, CircularProgress, TextField, Tooltip } from '@mui/material';
import { db } from '../FirebaseInit';
import { doc, onSnapshot, collection, query, where, getDocs, orderBy, deleteDoc, addDoc, updateDoc, getDoc, setDoc, limit, increment } from "firebase/firestore";
import Switch from 'react-switch';
import dayjs from 'dayjs';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import FullAutoStep1 from './FullAutoStep1';
import FullAutoStep2 from './FullAutoStep2';
import FullAutoStep3 from './FullAutoStep3';
import FullAutoStep4 from './FullAutoStep4';
import FullAutoStep5 from './FullAutoStep5';


 function FullAuto({uid, setBatchName, batchName, setSelectedPromptID, selectedPromptRevisionIDs, setSelectedPromptRevisionIDs,
    filteredPrompts, searchPromptsText, setSearchPromptsText, setSelectedAIModel, selectedAIModel,
    generateBatch, generateBatchLoading, setGenerateBatchLoading, setCreativity, creativity, setAutopilot, autopilot, setPromptChain, promptChain,
    setMJMLChangeCount, MJMLChangeCount, setMJMLRestructureCount, MJMLRestructureCount, setHTMLRestructureCount, HTMLRestructureCount,
    setHTMLMoreRobustCount, HTMLMoreRobustCount, setAutoApplyAutoFix, autoApplyAutoFix, newBatchHideSettings, setNewBatchHideSettings,
    mailingLists, serverURL, fullAutoSpreadSetting, setFullAutoSpreadSetting, setAutoInsertTemplatingVariables, autoInsertTemplatingVariables, 
    fullAutoMailings, setFullAutoMailings, fullAutoComputedMailings, setFullAutoComputedMailings, setShowBatches, 
    autoInsertWeatherConvo, setAutoInsertWeatherConvo, fullAutoDBID, setFullAutoDBID
}) {



    const [selectedDateFilter, setSelectedDateFilter] = useState('1');
    const [dateStart, setDateStart] = useState(dayjs().subtract(160, 'day').startOf('day'));
    const [dateEnd, setDateEnd] = useState(dayjs().endOf('day'));
    const [totalQuotaInformation, setTotalQuotaInformation] = useState({});
    const [selectedMailingList, setSelectedMailingList] = useState(mailingLists[0]);
    const [selectedMailingListID, setSelectedMailingListID] = useState(mailingLists[0]?.master_id);
    const [rawMailingListSize, setRawMailingListSize] = useState(0);
    const [loadingListSize, setLoadingListSize] = useState(false);
    const [manuallyAssignedListPercent, setManuallyAssignedListPercent] = useState([]);
    const [activeAccounts, setActiveAccounts] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [loadingAccounts, setLoadingAccounts] = useState(false);
    const [suggestedOffers, setSuggestedOffers] = useState([]);
    const [selectedOffers, setSelectedOffers] = useState([]);
    const [loadingOffers, setLoadingOffers] = useState(false);
    const [multipleOffersPerAccount, setMultipleOffersPerAccount] = useState(true);
    const [maxRecipientsPerCreative, setMaxRecipientsPerCreative] = useState(20000);
    const [savingChanges, setSavingChanges] = useState(false);

    const [selectedStep, setSelectedStep] = useState(1); 


    useEffect(() => {
        getTopOffers()
    }, [dateStart])


    useEffect(() => {
        getTotalQuota()
        getAccounts()
        createFullAutoReference()
    }, [])


    useEffect(() => {
        async function getListSize () {
          if (selectedMailingListID != '' ) {
            setLoadingListSize(true)
            console.log("GETTING LIST SIZE")
            console.log(`http://64.31.61.10/RNLeDUqAwJkC/api.php?desiredData=getListSize&listTableName=${selectedMailingList?.table_name}`)
            const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: `http://64.31.61.10/RNLeDUqAwJkC/api.php?desiredData=getListSize&listTableName=${selectedMailingList?.table_name}`});
            console.log(response.data)
            setRawMailingListSize(response?.data?.data[0]?.count * 1)
            setLoadingListSize(false)
          }
        }
        getListSize()
    }, [selectedMailingList])


    async function getTopOffers() {
        try {
          setLoadingOffers(true)
            console.log(`${serverURL}/api/getTopOffers?dateStart=${dateStart.format('YYYY-MM-DD')}&dateEnd=${dateEnd.format('YYYY-MM-DD')}`)
            const response = await axios.get(`${serverURL}/api/getTopOffers?dateStart=${dateStart.format('YYYY-MM-DD')}&dateEnd=${dateEnd.format('YYYY-MM-DD')}`)
            console.log(response.data)
            setSuggestedOffers(response.data)
            setLoadingOffers(false)
        } catch (error) {
            console.error('There was an error getting the top offers:', error)
            setLoadingOffers(false)
        }
    }

    async function getTotalQuota() {
        try {
            const response = await axios.get(`${serverURL}/api/getTotalSendwellAutomationAccountQuotas`)
            console.log(response.data)
            setTotalQuotaInformation(response.data);
        } catch (error) {
            console.error('There was an error getting the total quota:', error)
        }
    }


    async function getAccounts () {
      try {
      setLoadingAccounts(true)
       
      const response = await axios.get(`${serverURL}/api/getQuotas`)
      const acts = response.data.byAccount
      setActiveAccounts(acts)

      setLoadingAccounts(false)
      } catch (error) {
        console.error('There was an error getting the accounts:', error)
      }
    }




    useEffect(() => {
        //setDateStart(dayjs().subtract(selectedDateFilter, 'day').startOf('day'));
    }, [selectedDateFilter]);







    let totalListPercent = 0;
    for (let i = 0; i < selectedPromptRevisionIDs.length; i++) {
        totalListPercent += selectedPromptRevisionIDs[i].listPercent;
    }









    const nextButtonClassName = () => {
      if (selectedStep == 1 && selectedMailingListID == '') {
        return 'FullAutoBottomNextDisabled';
      } else if (selectedStep == 1 && selectedMailingListID != '') {
        return 'FullAutoBottomNext';
      } if (selectedStep == 2 && selectedAccounts.length == 0) {
        return 'FullAutoBottomNextDisabled';
      } else if (selectedStep == 2 && selectedAccounts.length != 0) {
        return 'FullAutoBottomNext';
      } if (selectedStep == 3 && selectedOffers.length == 0) {
        return 'FullAutoBottomNextDisabled';
      } else if (selectedStep == 3 && selectedOffers.length != 0) {
        return 'FullAutoBottomNext';
      } if (selectedStep == 4 && selectedAccounts.length == 0) {
        return 'FullAutoBottomNextDisabled';
      } else if (selectedStep == 4 && selectedAccounts.length != 0) {
        return 'FullAutoBottomNext';
      } else if (selectedStep == 5 && fullAutoComputedMailings.length == 0) {
        return 'FullAutoBottomNextDisabled';
      } else if (selectedStep == 5 && fullAutoComputedMailings.length != 0) {
        return 'FullAutoBottomNext';
      }
      return '';
    };






    function randomCharacters(length) {
      const characters = 'abcdefghijklmnopqrstuvwxyz';
      let result = '';
      for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
  }



    async function createFullAutoReference() {
      try {
        const fullAutoID = randomCharacters(20);
        setFullAutoDBID(fullAutoID)

        await setDoc(doc(db, "fullAutoConfigs", fullAutoID), {
          uid: uid,
          timestamp: new Date(),
          mailingListID: selectedMailingListID,
          accounts: selectedAccounts,
          offers: selectedOffers,
          autopilot: autopilot,
          autoInsertTemplatingVariables: autoInsertTemplatingVariables,
          AIModel: selectedAIModel,
          maxRecipientsPerCreative: maxRecipientsPerCreative,
          creativity: creativity,
          MJMLChangeCount: MJMLChangeCount,
          MJMLRestructureCount: MJMLRestructureCount,
          HTMLRestructureCount: HTMLRestructureCount,
          HTMLMoreRobustCount: HTMLMoreRobustCount,
          autoApplyAutoFix: autoApplyAutoFix,
          fullAutoMailings: fullAutoMailings,
          fullAutoComputedMailings: fullAutoComputedMailings,
          multipleOffersPerAccount: multipleOffersPerAccount,

          
        });

      } catch (error) {
        console.error('There was an error creating the full auto reference:', error)
      }
    }






    useEffect(() => {
        saveFullAutoConfig()
    }, [selectedMailingList, selectedAccounts, selectedOffers, fullAutoMailings])


    async function saveFullAutoConfig() {
      try {
        setSavingChanges(true)
        console.log(fullAutoDBID)
        await updateDoc(doc(db, "fullAutoConfigs", fullAutoDBID), {
          uid: uid,
          timestamp: new Date(),
          mailingListID: selectedMailingListID,
          accounts: selectedAccounts,
          offers: selectedOffers,
          autopilot: autopilot,
          autoInsertTemplatingVariables: autoInsertTemplatingVariables,
          AIModel: selectedAIModel,
          maxRecipientsPerCreative: maxRecipientsPerCreative,
          creativity: creativity,
          MJMLChangeCount: MJMLChangeCount,
          MJMLRestructureCount: MJMLRestructureCount,
          HTMLRestructureCount: HTMLRestructureCount,
          HTMLMoreRobustCount: HTMLMoreRobustCount,
          autoApplyAutoFix: autoApplyAutoFix,
          fullAutoMailings: fullAutoMailings,
          fullAutoComputedMailings: fullAutoComputedMailings,
          multipleOffersPerAccount: multipleOffersPerAccount,

          
        });

        setSavingChanges(false)
      } catch (error) {
        setSavingChanges(false)
        console.error('There was an error saving the full auto config:', error)
      }
    }




    return ( 
      <div className='FullAutoContainer'>
        <div className='FullAutoTop'>
        <div className='FullAutoTopItemContainer'>
            {/* <span className='FullAutoTopItemTitle'>Selected List</span> */}
            <div className={loadingListSize ? 'FullAutoTopItemCardLoading' : 'FullAutoTopItemCard'} style={{border: selectedStep == 1 && '2px solid #000'}} onClick={() => {setSelectedStep(1)}}>
              {loadingListSize ?
                <>
                  <CircularProgress style={{width: 25, height: 25, color: '#444'}}/>
                  <span className='FullAutoTopItemCardLoadingText'>Loading List</span>
                </>
              :
                <>
                  <span className='FullAutoTopItemCardTitle'>{selectedMailingList.table_name}</span>
                  <span className='FullAutoTopItemCardValue'>{new Intl.NumberFormat('en-US').format(rawMailingListSize)} Addresses</span>
                </>
              }
            </div>
          </div>
          <div className='FullAutoTopItemContainer'>
            {/* <span className='FullAutoTopItemTitle'>Selected Accounts</span> */}
            <div className={loadingAccounts ? 'FullAutoTopItemCardLoading' : 'FullAutoTopItemCard'} style={{border: selectedStep == 2 && '2px solid #000'}} onClick={() => {setSelectedStep(2)}}>
              {loadingAccounts ?
                <>
                  <CircularProgress style={{width: 25, height: 25, color: '#444'}}/>
                  <span className='FullAutoTopItemCardLoadingText'>Loading Accounts</span>
                </>
              :
                <>
                  <span className='FullAutoTopItemCardTitle'>{selectedAccounts.length} Accounts Selected</span>
                  <span className='FullAutoTopItemCardValue'>{new Intl.NumberFormat('en-US').format(selectedAccounts.reduce((accumulator, currentItem) => {return accumulator + currentItem.newQuota}, 0))} Quota</span>
                  <span className='FullAutoTopItemCardValue'>{activeAccounts.length} Active Account{activeAccounts.length == 1 ? "" : "s"} Available</span>
                </>
              }
            </div>
          </div>
          <div className='FullAutoTopItemContainer'>
            {/* <span className='FullAutoTopItemTitle'>Selected Offers</span> */}
            <div className={loadingOffers ? 'FullAutoTopItemCardLoading' : 'FullAutoTopItemCard'} style={{border: selectedStep == 3 && '2px solid #000'}} onClick={() => {setSelectedStep(3)}}>
              {loadingOffers ?
                <>
                  <CircularProgress style={{width: 25, height: 25, color: '#444'}}/>
                  <span className='FullAutoTopItemCardLoadingText'>Loading Offers</span>
                </>
              :
                <>
                  <span className='FullAutoTopItemCardTitle'>{selectedOffers.length} Offers Selected</span>
                  <span className='FullAutoTopItemCardValue'>{multipleOffersPerAccount ? "Multiple offers per account" : "One offer per account"}</span>
                </>
              }
            </div>
          </div>
          <div className='FullAutoTopItemContainer'>
            {/* <span className='FullAutoTopItemTitle'>Settings</span> */}
            <div className='FullAutoTopItemCard' style={{border: selectedStep == 4 && '2px solid #000'}} onClick={() => {setSelectedStep(4)}}>
              <span className='FullAutoTopItemCardTitle'>{selectedAIModel}</span>
              <span className='FullAutoTopItemCardValue'>Autopilot {autopilot ? "on" : "off"}</span>
              <span className='FullAutoTopItemCardValue'>Templating {autoInsertTemplatingVariables ? "on" : "off"}</span>
            </div>
          </div>
          <div className='FullAutoTopItemContainer'>
            {/* <span className='FullAutoTopItemTitle'>Settings</span> */}
            <div className='FullAutoTopItemCard' style={{border: selectedStep == 5 && '2px solid #000'}} onClick={() => {setSelectedStep(5)}}>
              <span className='FullAutoTopItemCardTitle'>{fullAutoComputedMailings.length} Mailings</span>
              <span className='FullAutoTopItemCardValue'>{maxRecipientsPerCreative != 20000000 ? new Intl.NumberFormat('en-US').format(maxRecipientsPerCreative) + " recipients per creative" : "Unlimited recipients per creative"}</span>
              <span className='FullAutoTopItemCardValue'></span>
            </div>
          </div>
        </div>
        <div className='FullAutoMiddle'>
        <FullAutoStep1 selectedStep={selectedStep} mailingLists={mailingLists} selectedMailingList={selectedMailingList} setSelectedMailingList={setSelectedMailingList} selectedMailingListID={selectedMailingListID} setSelectedMailingListID={setSelectedMailingListID}/>
        <FullAutoStep2 selectedStep={selectedStep} activeAccounts={activeAccounts} selectedAccounts={selectedAccounts} setSelectedAccounts={setSelectedAccounts}/>
        <FullAutoStep3 selectedStep={selectedStep} suggestedOffers={suggestedOffers} selectedOffers={selectedOffers} setSelectedOffers={setSelectedOffers} multipleOffersPerAccount={multipleOffersPerAccount} setMultipleOffersPerAccount={setMultipleOffersPerAccount}
          maxRecipientsPerCreative={maxRecipientsPerCreative} setMaxRecipientsPerCreative={setMaxRecipientsPerCreative}/>
        <FullAutoStep4 selectedStep={selectedStep} setCreativity={setCreativity} creativity={creativity} setAutopilot={setAutopilot} autopilot={autopilot} setMJMLChangeCount={setMJMLChangeCount} 
          MJMLChangeCount={MJMLChangeCount} setMJMLRestructureCount={setMJMLRestructureCount} MJMLRestructureCount={MJMLRestructureCount} 
          setHTMLRestructureCount={setHTMLRestructureCount} HTMLRestructureCount={HTMLRestructureCount} setHTMLMoreRobustCount={setHTMLMoreRobustCount} 
          HTMLMoreRobustCount={HTMLMoreRobustCount} setAutoApplyAutoFix={setAutoApplyAutoFix} autoApplyAutoFix={autoApplyAutoFix}
          setAutoInsertTemplatingVariables={setAutoInsertTemplatingVariables} autoInsertTemplatingVariables={autoInsertTemplatingVariables}
          setSelectedAIModel={setSelectedAIModel} selectedAIModel={selectedAIModel} maxRecipientsPerCreative={maxRecipientsPerCreative} setMaxRecipientsPerCreative={setMaxRecipientsPerCreative}
          autoInsertWeatherConvo={autoInsertWeatherConvo} setAutoInsertWeatherConvo={setAutoInsertWeatherConvo}/>
        <FullAutoStep5 selectedStep={selectedStep} fullAutoMailings={fullAutoMailings} setFullAutoMailings={setFullAutoMailings} multipleOffersPerAccount={multipleOffersPerAccount} setMultipleOffersPerAccount={setMultipleOffersPerAccount}
          selectedAccounts={selectedAccounts} selectedOffers={selectedOffers} maxRecipientsPerCreative={maxRecipientsPerCreative} setMaxRecipientsPerCreative={setMaxRecipientsPerCreative} 
          fullAutoComputedMailings={fullAutoComputedMailings} setFullAutoComputedMailings={setFullAutoComputedMailings} rawMailingListSize={rawMailingListSize} selectedMailingList={selectedMailingList}/>
        </div>
        <div className='FullAutoBottom'>
          <span className='FullAutoBottomBack'>Back</span>
          <div className='FullAutoBottomRightContainer'>
            {savingChanges ?
            <div className='FullAutoBottomRightContainer'>
              <CircularProgress style={{width: 25, height: 25, color: '#888', marginRight: 15}}/>
              <span className='FullAutoBottomRightSavingText'>Saving changes...</span>
            </div>
            :
              <span className='FullAutoBottomRightSavingText'>Changes Saved</span>
            }

            <span 
              className={nextButtonClassName()} 
              onClick={() => {
                if (selectedStep == 5 && fullAutoComputedMailings.length > 0) {
                  generateBatch(true)
                  setShowBatches(false)
                } else {
                  setSelectedStep(selectedStep + 1)
                }
              }}
            >
              {generateBatchLoading ? 
                <CircularProgress style={{width: 25, height: 25, color: '#fff'}}/>
              : 
                selectedStep == 5 && fullAutoComputedMailings.length > 0 ? "Generate" : "Next"
              }
            </span>
          </div>
        </div>
      </div>
     );
}

export default FullAuto;