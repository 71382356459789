import {React, useState} from 'react';
import './Links.css';
import axios from 'axios';


function ImageGen() {
    const [image, setImage] = useState([]);
    const [used, setUsed] = useState(false);
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);

    console.log(showCopiedMessage)


    //const serverURL = 'http://api.automail.sendwell.com'
    const serverURL = 'https://roboapi.purbooks.com'
    //const serverURL = 'https://stevenbrekalo.com:3000'



    async function generateImage() {
      const startTime = Date.now();
      try {
          const response = await axios.get(`${serverURL}/api/generateImage`);
          const imageResponse = response.data.data;
          setImage(imageResponse);
          setUsed(false);
          const elapsedTime = Date.now() - startTime;
          console.log(`Time taken to get response: ${elapsedTime} milliseconds`);
      } catch (error) {
          console.error('There was an error generating an image:', error);
      }
  }


      function copyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
    
        // Set the value of the textArea to the text to be copied
        textArea.value = text;
    
        // Make the textArea hidden
        textArea.style.position = "fixed";
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.width = "1px";
        textArea.style.height = "1px";
        textArea.style.opacity = "0";
    
        // Append the textArea to the document body
        document.body.appendChild(textArea);
    
        // Focus and select the text inside the textArea
        textArea.focus();
        textArea.select();
    
        try {
            // Execute the copy command
            var successful = document.execCommand('copy');
            var message = successful ? 'Copied to clipboard!' : 'Unable to copy to clipboard';
            console.log(message);
        } catch (err) {
            console.error('Unable to copy to clipboard:', err);
        }
    
        // Remove the textArea from the document body
        document.body.removeChild(textArea);
      }


      

    return ( 
      <div className='LinksContainer'>
      <span onClick={() => {generateImage()}} className='getLinksButton'>Generate Image</span>



      {image != '' ?
        <div className='linkRow'>
            <span
              className={used ? 'usedLinkContainer' : 'linkContainer'}
              onClick={() => {copyTextToClipboard(image); setUsed(true);}}
            >
              {used ? <span className='usedLinkMarker'>USED</span> : <span className='usedLinkMarker'>NEW</span>}
              <span onClick={() => {copyTextToClipboard(image); setUsed(true);}}>{image}</span>

              
            </span>
          </div>
        : 
          <></>
        }


           

        {showCopiedMessage ? 
            <span
            className='linksCopiedMessage'
            >
            Copied to clipboard
            </span>
        : ''}            
        </div>
     );
}

export default ImageGen;