// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";

/*
const firebaseConfig = {
  apiKey: "AIzaSyBRCMcZv94VXJJKDdLTx-EUzFyqTp62Wuk",
  authDomain: "t3-tools.firebaseapp.com",
  projectId: "t3-tools",
  storageBucket: "t3-tools.appspot.com",
  messagingSenderId: "574806404209",
  appId: "1:574806404209:web:c2a7440c35b0ef624a3fd0",
  measurementId: "G-FKF1B944LE"
};
*/

const firebaseConfig = {
  apiKey: "AIzaSyCVbkIERXpn57FoxOKkP9T40h1i0JXgezw",
  authDomain: "mike-automail.firebaseapp.com",
  projectId: "mike-automail",
  storageBucket: "mike-automail.appspot.com",
  messagingSenderId: "458561547335",
  appId: "1:458561547335:web:6adb481ba11bbc1e099562",
  measurementId: "G-1P4VEHWS0G"
};



const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();

const provider = new GoogleAuthProvider();



export {db};