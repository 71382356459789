

import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import './NewCreativeFullAuto.css';
import { ClearRounded, KeyboardArrowDownRounded, SearchRounded, Settings } from '@mui/icons-material';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import Switch from 'react-switch';
import dayjs from 'dayjs';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';


 function NewCreativeFullAuto({setBatchName, batchName, setSelectedPromptID, selectedPromptRevisionIDs, setSelectedPromptRevisionIDs,
    filteredPrompts, searchPromptsText, setSearchPromptsText, setSelectedAIModel, selectedAIModel,
    generateBatch, generateBatchLoading, setGenerateBatchLoading, setCreativity, creativity, setAutopilot, autopilot, setPromptChain, promptChain,
    setMJMLChangeCount, MJMLChangeCount, setMJMLRestructureCount, MJMLRestructureCount, setHTMLRestructureCount, HTMLRestructureCount,
    setHTMLMoreRobustCount, HTMLMoreRobustCount, setAutoApplyAutoFix, autoApplyAutoFix, newBatchHideSettings, setNewBatchHideSettings,
    mailingLists, serverURL, fullAutoSpreadSetting, setFullAutoSpreadSetting, setAutoInsertTemplatingVariables, autoInsertTemplatingVariables,
    autoInsertWeatherConvo, setAutoInsertWeatherConvo
}) {

    const [selectedDateFilter, setSelectedDateFilter] = useState('1');
    const [dateStart, setDateStart] = useState(dayjs().subtract(101, 'day').startOf('day'));
    const [dateEnd, setDateEnd] = useState(dayjs().endOf('day'));
    const [suggestedOffers, setSuggestedOffers] = useState([]);
    const [totalQuotaInformation, setTotalQuotaInformation] = useState({});
    const [selectedMailingList, setSelectedMailingList] = useState(mailingLists[0]);
    const [selectedMailingListID, setSelectedMailingListID] = useState(mailingLists[0]?.master_id);
    const [rawMailingListSize, setRawMailingListSize] = useState(0);
    const [loadingListSize, setLoadingListSize] = useState(false);
    const [manuallyAssignedListPercent, setManuallyAssignedListPercent] = useState([]);


    useEffect(() => {
        getTopOffers()
    }, [dateStart])


    useEffect(() => {
        getTotalQuota()
    }, [])


    useEffect(() => {
        async function getListSize () {
            if (selectedMailingListID != '' ) {
            setLoadingListSize(true)
            console.log("GETTING LIST SIZE")
            console.log(`http://64.31.61.10/RNLeDUqAwJkC/api.php?desiredData=getListSize&listTableName=${selectedMailingList?.table_name}`)
            const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: `http://64.31.61.10/RNLeDUqAwJkC/api.php?desiredData=getListSize&listTableName=${selectedMailingList?.table_name}`});
            console.log(response.data)
            setRawMailingListSize(response?.data?.data[0]?.count * 1)
            setLoadingListSize(false)
            }
        }
        getListSize()
    }, [selectedMailingList])


    async function getTopOffers() {
        try {
            console.log(`${serverURL}/api/getTopOffers?dateStart=${dateStart.format('YYYY-MM-DD')}&dateEnd=${dateEnd.format('YYYY-MM-DD')}`)
            const response = await axios.get(`${serverURL}/api/getTopOffers?dateStart=${dateStart.format('YYYY-MM-DD')}&dateEnd=${dateEnd.format('YYYY-MM-DD')}`)
            console.log(response.data)
            setSuggestedOffers(response.data)
        } catch (error) {
            console.error('There was an error getting the top offers:', error)
        }
    }

    async function getTotalQuota() {
        try {
            const response = await axios.get(`${serverURL}/api/getTotalSendwellAutomationAccountQuotas`)
            console.log(response.data)
            setTotalQuotaInformation(response.data);
        } catch (error) {
            console.error('There was an error getting the total quota:', error)
        }
    }



    useEffect(() => {
        setDateStart(dayjs().subtract(selectedDateFilter, 'day').startOf('day'));
    }, [selectedDateFilter]);







let totalListPercent = 0;
for (let i = 0; i < selectedPromptRevisionIDs.length; i++) {
    totalListPercent += selectedPromptRevisionIDs[i].listPercent;
}






const handlePromptSelection = (newRevision) => {
    setSelectedPromptRevisionIDs(prevIDs => {
        // Check if newRevision.id is already present in the array
        const index = prevIDs.findIndex(item => item.id === newRevision.id);
        
            // If newRevision.id is not present, add it to the array with count set to 1
            const updatedIDs = [...prevIDs, { 
                promptName: newRevision.promptName, 
                id: newRevision.id, 
                promptID: newRevision.promptID, 
                count: 1
            }];

            // Calculate the equal list percent for all items
            const equalListPercent = ((totalQuotaInformation?.totalQuota / rawMailingListSize) * 100) / updatedIDs.length;

            // Ensure the equal list percent does not exceed 100
            const finalListPercent = equalListPercent > 100 ? 100 : equalListPercent;

            // Update listPercent for all items
            const updatedIDsWithPercent = updatedIDs.map(item => ({
                ...item,
                listPercent: parseFloat(finalListPercent.toFixed(2))
            }));

            assignAccounts(updatedIDsWithPercent)
            return updatedIDsWithPercent;
        })
        setManuallyAssignedListPercent([])
  
};








      
    
    
    
const handlePromptRemoval = (removedRevision) => {
    setSelectedPromptRevisionIDs(prevIDs => {
        // Find the index of the item with the same ID in the array
        const index = prevIDs.findIndex(item => item.id === removedRevision.id);
        
                // If the count is 1, remove the item from the array
                const updatedIDs = prevIDs.filter((item, idx) => idx !== index);
                
                if (updatedIDs.length === 0) {
                    // If no items are left, return an empty array
                    return [];
                }
                
                // Calculate the equal list percent for all remaining items
                const equalListPercent = ((totalQuotaInformation?.totalQuota / rawMailingListSize) * 100) / updatedIDs.length;

                // Ensure the equal list percent does not exceed 100
                const finalListPercent = equalListPercent > 100 ? 100 : equalListPercent;

                // Update listPercent for all remaining items
                const updatedIDsWithPercent = updatedIDs.map(item => ({
                    ...item,
                    listPercent: parseFloat(finalListPercent.toFixed(2))
                }));

                return updatedIDsWithPercent;
    });

    setManuallyAssignedListPercent([])

};







async function assignAccounts(updatedIDsWithPercent) {
    try {
      //console.log(updatedIDsWithPercent)
        const data = {
            selectedPromptRevisionIDs: updatedIDsWithPercent ? updatedIDsWithPercent : selectedPromptRevisionIDs,
            rawMailingListSize: rawMailingListSize,
            accountQuotas: totalQuotaInformation?.byAccount,
            mailingListTableName: selectedMailingList.table_name,
            mailingListTableID: selectedMailingList.master_id,
        }

        console.log(data)

        const response = await axios.post(`${serverURL}/api/assignAccountsToOffers`, data)
        setSelectedPromptRevisionIDs(response.data);

        // Wait for a few seconds before returning the response
        await new Promise(resolve => setTimeout(resolve, 3000)); // 3000 milliseconds = 3 seconds

        return response.data;
    } catch (error) {
        console.error('There was an error assigning accounts:', error)
    }
}




console.log(manuallyAssignedListPercent)





    return ( 
      <div className='ChatGPTNewCreativeContainer'>
        <div className='ChatGPTNewCreativeContainerHeader'>
          <Settings style={{opacity: 0}}/>
          <span className='ChatGPTNewCreativeTitle'>New Batch in Full Auto</span>
          <div className='ChatGPTNewCreativeHeaderSettingsIcon' style={{opacity: 0}} onClick={() => {}}>
            <Settings/>
          </div>
        </div>

        <div className='ChatGPTNewCreativeContainerSubContainer'>

            <div className='ChatGPTNewCreativeRow' style={{marginLeft: 0}}>

                <div className='ChatGPTNewCreativeInputWrapper'>
                <span className='ChatGPTNewCreativeInputName'>Batch Name</span>
                <input className='ChatGPTNewCreativeInput' type='text' placeholder='Ex: Costco 1R1' onChange={(event) => {setBatchName(event.target.value)}} value={batchName}/>
                </div>

            </div>



            <div className='ChatGPTCreateMailingAccountSelectorContainer'>
                <select
                className='ChatGPTNewCreativeInput'
                    value={selectedMailingListID}
                    onChange={(event) => {
                        const selectedID = event.target.value;
                        const selectedMailingList = mailingLists.find(list => list.master_id === selectedID);
                        setSelectedMailingList(selectedMailingList);
                        setSelectedMailingListID(selectedID);
                    }}
                >
                    <option value="">Select a Mailing List</option>
                    {mailingLists.map((list) => (
                        <option key={list.master_id} value={list.master_id}>
                            {list.table_name}
                        </option>
                    ))}
                </select>

            </div>



        {/* 
            <div className='ChatGPTFullAutoSpreadContainer'>
                <span className={fullAutoSpreadSetting == 'splitQuotaEvenly' ? 'ChatGPTFullAutoSpreadOptionSelected' : 'ChatGPTFullAutoSpreadOption'} onClick={() => {setFullAutoSpreadSetting('splitQuotaEvenly')}}>Split Quota Evenly</span>
                <span className={fullAutoSpreadSetting == 'entireListToEachOffer' ? 'ChatGPTFullAutoSpreadOptionSelected' : 'ChatGPTFullAutoSpreadOption'} onClick={() => {setFullAutoSpreadSetting('entireListToEachOffer')}}>Entire List To Each Offer</span>
                <span className={fullAutoSpreadSetting == 'custom' ? 'ChatGPTFullAutoSpreadOptionSelected' : 'ChatGPTFullAutoSpreadOption'} onClick={() => {setFullAutoSpreadSetting('custom')}}>Custom</span>
            </div>
         */}



            <span className='ChatGPTSavedPromptsRowTitle'>
                Selected Offers ({selectedPromptRevisionIDs.length}) 
                {totalQuotaInformation && totalQuotaInformation.totalQuota && rawMailingListSize ? (
                    " " + ((1 - (totalListPercent / ((totalQuotaInformation?.totalQuota / rawMailingListSize) * 100))).toFixed(3) * 100).toFixed(2) + "% Quota Remaining"
                ) : (
                    "   Loading Quota"
                )}
            </span>

            <div className='ChatGPTSavedPromptsRow' style={{maxHeight: 400}}>
                {selectedPromptRevisionIDs.map((x) => { return ( 
                  <Tooltip title={new Intl.NumberFormat('en-US').format(Math.floor(rawMailingListSize * (x.listPercent / 100)))} placement="right">
                  <div key={x?.id} className='ChatGPTNewCreativeSavePromptFullAutoOptionButton'>
                      <div className='ChatGPTNewCreativeSavePromptFullAutoOptionButtonTextContainer' onClick={() => {handlePromptRemoval({id: x.id, promptName: x.promptName, promptID: x.promptID}); setSelectedPromptID(x.id)}}>
                        <span className='ChatGPTNewCreativeSavePromptFullAutoOptionButtonPromptName'>{x.promptName}</span>
                        <div style={{display: 'flex', fontSize: 17, paddingTop: 3}}>
                        <input
                          type="number"
                          value={x.listPercent}
                          onChange={(e) => {
                            let newValue = parseFloat(e.target.value);
                        
                            // Ensure newValue is within valid range [0, 100]
                            if (isNaN(newValue) || newValue < 0) {
                                //newValue = 0;
                            } else if (newValue > 100) {
                                newValue = 100;
                            }
                        
                            // Update selectedPromptRevisionIDs with the new value
                            const updatedPromptRevisionIDs = selectedPromptRevisionIDs.map(item =>
                                item.id === x.id ? { ...item, listPercent: newValue } : item
                            );
                            setSelectedPromptRevisionIDs(updatedPromptRevisionIDs);
                        
                            // Update manuallyAssignedListPercent if it's not already included
                            if (!manuallyAssignedListPercent.includes(x.promptName)) {
                                setManuallyAssignedListPercent([...manuallyAssignedListPercent, x.promptName]);
                            }
                        
                            // Recalculate listPercent based on remaining quota
                            const manuallyAssignedPercentSum = updatedPromptRevisionIDs.reduce((sum, item) => {
                                if (manuallyAssignedListPercent.includes(item.promptName)) {
                                    return sum + parseFloat(item.listPercent);
                                }
                                return sum;
                            }, 0);
                        
                            const manuallyAssignedQuotaSum = manuallyAssignedPercentSum * (rawMailingListSize / 100);
                            const remainingQuota = totalQuotaInformation.totalQuota - manuallyAssignedQuotaSum;
                        
                            // Calculate equal list percent for non-manually assigned items
                            const nonManuallyAssignedItems = updatedPromptRevisionIDs.filter(item => !manuallyAssignedListPercent.includes(item.promptName));
                            const numNonManuallyAssigned = nonManuallyAssignedItems.length;
                            const equalListPercent = numNonManuallyAssigned > 0 ? (remainingQuota / numNonManuallyAssigned) / (rawMailingListSize / 100) : 0;
                        
                            // Update listPercent for all items
                            const updatedIDs = updatedPromptRevisionIDs.map(item => ({
                                ...item,
                                listPercent: manuallyAssignedListPercent.includes(item.promptName) ? parseFloat(item.listPercent.toFixed(2)) : parseFloat(equalListPercent.toFixed(2))
                            }));
                        
                            setSelectedPromptRevisionIDs(updatedIDs);
                        }}
                        



                          
                          onClick={(e) => e.stopPropagation()} 
                          className="ChatGPTListPercentInput"
                        />

                          %
                        </div>                    
                      </div> 
                    </div>
                  </Tooltip>
                )})}
            </div>




            {/* <div className='ChatGPTSavedPromptsRow' style={{maxHeight: 300}}>
              {selectedPromptRevisionIDs.map((x, index) => {
                return (
                  <div key={x.id} className='ChatGPTNewCreativeSavePromptFullAutoOptionButton'>
                    <div
                      className='ChatGPTNewCreativeSavePromptFullAutoOptionButtonTextContainer'
                      onClick={() => {
                        handlePromptRemoval({ id: x.id, promptName: x.promptName, promptID: x.promptID });
                        setSelectedPromptID(x.id);
                      }}
                    >
                      <span className='ChatGPTNewCreativeSavePromptFullAutoOptionButtonPromptName'>
                        {x.promptName}
                      </span>
                      <span className='ChatGPTNewCreativeSavePromptFullAutoOptionButtonPromptEPM'>
                        {new Intl.NumberFormat('en-US', { style: 'percent' }).format(x.listPercent / 100)}
                      </span>
                      <div style={{display: 'flex', fontSize: 17, paddingTop: 3}}>
                      <input
                        type="number"
                        value={manualPercentages[x.promptName] || x.listPercent}
                        onChange={(e) => handleListPercentChange(x.promptName, Math.floor(e.target.value))}
                        onClick={(e) => e.stopPropagation()} // Prevent parent onClick from being triggered
                        className="ChatGPTListPercentInput"
                        step="1"
                        min="0"
                      />
                      %
                      </div>
                    </div>
                  </div>
                );
              })}
            </div> */}





            <span className='ChatGPTSavedPromptsRowTitle'>Offers ({suggestedOffers?.length})</span>
            <div className='ChatGPTFullAutoSpreadContainer'>
                Show Stats From
                <span className={selectedDateFilter == '1' ? 'ChatGPTFullAutoSpreadOptionSelected' : 'ChatGPTFullAutoSpreadOption'} onClick={() => {setSelectedDateFilter('1')}}>Today</span>
                <span className={selectedDateFilter == '2' ? 'ChatGPTFullAutoSpreadOptionSelected' : 'ChatGPTFullAutoSpreadOption'} onClick={() => {setSelectedDateFilter('2')}}>Yesterday</span>
                <span className={selectedDateFilter == '7' ? 'ChatGPTFullAutoSpreadOptionSelected' : 'ChatGPTFullAutoSpreadOption'} onClick={() => {setSelectedDateFilter('7')}}>7 days</span>
                <span className={selectedDateFilter == '30' ? 'ChatGPTFullAutoSpreadOptionSelected' : 'ChatGPTFullAutoSpreadOption'} onClick={() => {setSelectedDateFilter('30')}}>30 days</span>
            </div>
            <div className='ChatGPTNewPromptSearchWrapper'>
                <span className='ChatGPTNewPromptSearchIcon'><SearchRounded/></span>
                <input className='ChatGPTNewPromptSearch' type='text' placeholder={`Search All Offers (${filteredPrompts?.length})`} value={searchPromptsText} onChange={(e) => {setSearchPromptsText(e.target.value)}}/>
                <span className='ChatGPTNewPromptSearchIcon' onClick={() => {setSearchPromptsText('')}}><ClearRounded/></span>
            </div> 
            <div className='ChatGPTSavedPromptsRow'>
                {searchPromptsText != '' ? 
                    <>
                        {filteredPrompts.map((x) => { return ( 
                        <div key={x?.promptID}  className={
                            selectedPromptRevisionIDs.map(item => item.promptName).includes(x.prompt_name) ? 
                            'ChatGPTNewCreativeSavePromptOptionButtonTextFullAutoSelected' : 
                            'ChatGPTNewCreativeSavePromptFullAutoOptionButton'
                        }>
                            <span className='ChatGPTNewCreativeSavePromptOptionButtonText' onClick={() => {handlePromptSelection({id: x.revisions[((x.revisions).length) - 1], promptName: x.promptName, promptID: x.id}); setSelectedPromptID(x.id) }}>{x.promptName}</span>
                        </div>
                        )})}
                    </>
                :
                    <div className='ChatGPTSavedPromptsRow'>
                        {suggestedOffers.map((x) => { return ( 
                        <div key={x?.id} className={
                            selectedPromptRevisionIDs.map(item => item.promptName).includes(x.prompt_name) ? 
                            'ChatGPTNewCreativeSavePromptOptionButtonTextFullAutoSelected' : 
                            'ChatGPTNewCreativeSavePromptFullAutoOptionButton'
                        }>
                            <div className='ChatGPTNewCreativeSavePromptFullAutoOptionButtonTextContainer' onClick={() => {handlePromptSelection({id: x.revisions[((x.revisions).length) - 1], promptName: x.prompt_name, promptID: x.id}); setSelectedPromptID(x.id) }}>
                                <span className=''>{x.prompt_name}</span>
                                <span className='ChatGPTNewCreativeSavePromptFullAutoOptionButtonPromptEPM'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.epm)} EPM</span>
                            </div> 
                        </div>
                        )})}
                    </div>
                }
            </div>

            




            <div className='ChatGPTFullAutoQuotaInformationContainer'>
                <div className='ChatGPTFullAutoQuotaInformationRow'>
                    <span className='ChatGPTFullAutoQuotaInformationName'>Available Accounts</span>
                    <span className='ChatGPTFullAutoQuotaInformationValue'>{totalQuotaInformation?.numberOfAccounts || "Loading"}</span>
                </div>
                <div className='ChatGPTFullAutoQuotaInformationRow'>
                    <span className='ChatGPTFullAutoQuotaInformationName'>Total Quota</span>
                    <span className='ChatGPTFullAutoQuotaInformationValue'>{totalQuotaInformation?.totalQuota ? new Intl.NumberFormat('en-US').format(totalQuotaInformation?.totalQuota) : "Loading"}</span>
                </div>
                <div className='ChatGPTFullAutoQuotaInformationRow'>
                    <span className='ChatGPTFullAutoQuotaInformationName'>Mailing List Size</span>
                    <span className='ChatGPTFullAutoQuotaInformationValue'>{rawMailingListSize ? new Intl.NumberFormat('en-US').format(rawMailingListSize) : "Loading"}</span>
                </div>
            </div>




            <div className='ChatGPTNewBatchSettingsDropdownButtonRow' onClick={() => {setNewBatchHideSettings(!newBatchHideSettings)}}>
              <span>{newBatchHideSettings ? 'Show' : 'Hide'} batch settings</span>
              <span style={{transform: newBatchHideSettings ? '' : 'rotate(180deg)', transition: 'all 0.5s', display: 'block'}}><KeyboardArrowDownRounded/></span>
            </div>
            <div className={newBatchHideSettings ? 'ChatGPTNewBatchSettingsDropdownClosed' : 'ChatGPTNewBatchSettingsDropdownOpen'}>

              <div className='ChatGPTNewCreativeInputWrapper'>
                <span className='ChatGPTNewCreativeInputName'>Creativity</span>
                <select
                  className='ChatGPTNewCreativeInputDropdown'
                  value={creativity}
                  onChange={(e) => setCreativity(e.target.value)}
                >
                <option value='1.0'>Very High</option>
                <option value="0.8">High</option>
                <option value="0.7">Normal</option>
                <option value="0.5">Low</option>
                <option value="0.1">Very Low</option>
                </select>
              </div>


              <div className='ChatGPTNewCreativeInputWrapper'>
                <span className='ChatGPTNewCreativeInputName'>AI Model</span>
                <select
                  className='ChatGPTNewCreativeInputDropdown'
                  value={selectedAIModel}
                  onChange={(e) => setSelectedAIModel(e.target.value)}
                >
                  <option value='gpt-4o'>GPT 4o (Always most updated)</option>
                  <option value='gpt-4o-2024-05-13'>GPT 4o-2024-05-13</option>
                  <option value='gpt-4-1106-preview'>GPT 4-Turbo V1 (1106) 128K/4K (Recommended)</option>
                  <option value='gpt-4-turbo-preview'>GPT 4-Turbo V2 (0125) 128K/4K</option>
                  <option value='gpt-4'>GPT 4 8K/8K</option>
                  <option value='gpt-3.5-turbo-16k'>GPT 3.5-Turbo 16K/16k</option>
                  <option value='gpt-3.5-turbo'>GPT 3.5-Turbo 16K/4K</option>
                </select>
              </div>

              <Tooltip title="Automatically run prompts consecutively to get you a finished product with 1 click" placement="bottom">
                <div className='ChatGPTNewCreativeRow'>
                  <span className='ChatGPTNewCreativeRowText'>Autopilot</span>
                  <Switch
                    onChange={(checked) => setAutopilot(checked)}
                    checked={autopilot}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                </div>
              </Tooltip>


              {autopilot ?
                <>

                  {/*
                    <Tooltip title="Run Autopilot with prompt chaining" placement="bottom">
                      <div className='ChatGPTNewCreativeRow'>
                        <span className='ChatGPTNewCreativeRowText'>Prompt Chaining</span>
                        <Switch
                          onChange={(checked) => setPromptChain(checked)}
                          checked={promptChain}
                          offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                        />
                      </div>
                    </Tooltip>
                  */}

                  <div className='ChatGPTNewCreativeInputWrapper'>
                    <span className='ChatGPTNewCreativeInputName'>How many times should MJML content be changed?</span>
                    <select
                      className='ChatGPTNewCreativeInputDropdown'
                      value={MJMLChangeCount}
                      onChange={(e) => setMJMLChangeCount(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value='5'>5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='ChatGPTNewCreativeInputWrapper'>
                    <span className='ChatGPTNewCreativeInputName'>How many times should MJML be restructured?</span>
                    <select
                      className='ChatGPTNewCreativeInputDropdown'
                      value={MJMLRestructureCount}
                      onChange={(e) => setMJMLRestructureCount(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value='5'>5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='ChatGPTNewCreativeInputWrapper'>
                    <span className='ChatGPTNewCreativeInputName'>How many times should HTML be restructured?</span>
                    <select
                      className='ChatGPTNewCreativeInputDropdown'
                      value={HTMLRestructureCount}
                      onChange={(e) => setHTMLRestructureCount(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value='5'>5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='ChatGPTNewCreativeInputWrapper'>
                    <span className='ChatGPTNewCreativeInputName'>How many times should HTML be made more robust?</span>
                    <select
                      className='ChatGPTNewCreativeInputDropdown'
                      value={HTMLMoreRobustCount}
                      onChange={(e) => setHTMLMoreRobustCount(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value='5'>5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>


                  <Tooltip title='Automatically inserts templating variables, {{top}}, {{middle}}, and {{bottom}}, in last revision' placement="bottom">
                    <div className='ChatGPTNewCreativeRow'>
                      <span className='ChatGPTNewCreativeRowText'>Auto Insert Templating Variables</span>
                      <Switch
                        onChange={(checked) => setAutoInsertTemplatingVariables(checked)}
                        checked={autoInsertTemplatingVariables}
                        offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                      />
                    </div>
                  </Tooltip>



                  <Tooltip title='Automatically runs preset "find and replaces" and fills variables in last revision' placement="bottom">
                    <div className='ChatGPTNewCreativeRow'>
                      <span className='ChatGPTNewCreativeRowText'>Autofix</span>
                      <Switch
                        onChange={(checked) => setAutoApplyAutoFix(checked)}
                        checked={autoApplyAutoFix}
                        offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                      />
                    </div>
                  </Tooltip>
              </>


              :<></>  
              }
            </div>

          {generateBatchLoading ?
            <span className='ChatGPTGenerateButton'>Loading - Please Wait</span>
          :
          <>
          {/* <span onClick={() => {assignAccounts()}}>ASSIGN</span> */}
            {selectedPromptRevisionIDs.length > 0 && batchName !== '' ? (
            <span className='ChatGPTGenerateButton' onClick={async () => {
                setGenerateBatchLoading(true)
                await assignAccounts()
                generateBatch(true, selectedPromptRevisionIDs);
                }}
            >
                Generate Batch
            </span>
            ) : (
            <></>
            )}

          </>
          }
        </div>
      </div>
     );
}

export default NewCreativeFullAuto;